import React, { useState } from "react";

import axios from "axios";

import { Link } from "gatsby";

import moment from "moment";

import { useForm, Controller } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";

import emailicon from "../../../assets/images/connect/email-icon.png"

import penicon from "../../../assets/images/connect/pen-icon.png"

// import { Modal, Spinner,Label,Select } from "react-bootstrap";

import "react-toastify/dist/ReactToastify.css";

import ideaform from "../../../assets/images/connect/idea-form-img.png";
const Shareideasseccontent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true);

    const dataObj = {};
    const todayVal = moment(new Date()).format("MM-DD-YYYY h:mm:ss a");
    const practicingnew = data.practiseing;
    dataObj.submitteddate = todayVal;

    dataObj.emailid = data.email;
    dataObj.idea = data.idea;
    dataObj.projectname = "SA";

    axios
      .post(
        "https://g6iqtcvhyl.execute-api.ap-south-1.amazonaws.com/api/sa/shareideas",
        dataObj
      )
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          toast.success("Form submitted successfully");
          reset();
        } else {
          setLoading(false);
          toast.error("something went wrong");
        }
      });
  };

  return (
    <section className="share-ideas-sec-content-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 ">
          <p className="theme-p-tag shareidea-p-tag">
              {" "}
             <b> Help us take it to the next level of transforming lives. Provide
              your new ideas to us below.</b>
            </p>
            <p className="theme-p-tag shareidea-p-tag">
              {" "}
              Do you have new ideas to contribute towards the Heartfulness
              approach to Sahaj Marg? Well you have come to the right place. If
              you are inspired and willing to share, we are here to listen. With
              your new and innovative ideas, together we could take Heartfulness
              to a whole new level.
            </p>
            <p className="theme-p-tag shareidea-p-tag">
              {" "}
              How can you do that? Just fill out the box below and we will get
              back to you.
            </p>
            <p className="theme-p-tag">
              {" "}
              For any further information related to share your experience in heartfulness 
              </p>
              <p className="theme-p-tag"><b>Please contact </b> </p>
              <p className="theme-p-tag">  
             <a className="" href="mailto:devanr@volunteer.heartfulness.org ">devanr@volunteer.heartfulness.org</a> <br/> 
              <a className="" href="mailto: rsa.secretary@heartfulness.org "> rsa.secretary@heartfulness.org </a> <br/> 
              <a className="" href="mailto: za.secretary@srcm.org ">   za.secretary@srcm.org</a>
            
              </p>

          </div>

          <div className="col-md-6">
            <img className="img-fluid border" src={ideaform} alt="youtube" />
          </div>
        </div>
      </div>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          newestOnTop={false}
          hideProgressBar
          closeOnClick
          rtl={false}
        />
      </div>
    </section>
  );
};

export default Shareideasseccontent;
