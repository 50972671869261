import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

// import Connectbreadcreumb from "../components/connect/Connectbreadcreumb";

import Sharebreadcreumb from "../components/connect/shareideas/shareideas-breadcumb.jsx";



import Sharepage from "../components/connect/shareideas/shareideas-sec-content";

// markup
const ConnectPage = () => {
  return (
    <div>
      <Seo title="Connect" />

      <Layout isSticky>
        <section>
          < Sharebreadcreumb />
        </section>

        <section>
          <Sharepage  />
        </section>

        
      </Layout>
    </div>
  );
};

export default ConnectPage;
